import { Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from "@mui/styles";
import Box from "@mui/material/Box/Box";

const Check = styled(CheckIcon)(({theme}) => ({
    color: '#0BC65A',
    height: 24,
    width: 24,
    marginBottom: -2
}));

const ErrorIcon = styled(ErrorOutlineIcon)(({theme}) => ({
    color: '#FF5140',
    height: 24,
    width: 24,
    marginBottom: -2
}));

const TextBox = styled(Box)(({theme, text}) => ({
    overflow: 'hidden',
    color: 'var(--green-source, #00A825);',
    fontFamily: 'Source Sans Pro',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.09px',visibility: text === "" ? 'hidden' : 'visible',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    flexDirection: 'row',
    textOverflow: 'ellipsis',
}));

const ErrorTextBox = styled(Box)(({theme, text}) => ({
    overflow: 'hidden',
    color: 'var(--Red-40, #FF5140)',
    fontFamily: 'Source Sans Pro',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.09px',visibility: text === "" ? 'hidden' : 'visible',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    flexDirection: 'row',
    textOverflow: 'ellipsis',
}));


export default function StatusText(props) {
    const {text, isError } = props;

    return(
        <>
        {!isError &&
                <TextBox text={text}>
                    <Check/>
                    <span>{text}</span>
                </TextBox>

        }
        {isError &&
                <ErrorTextBox >
                    <ErrorIcon/>
                    <span>{text}</span>
                </ErrorTextBox>
        }
        </>
    );
}