import { useTranslation } from "react-i18next";
import { Button } from './CustomUI';
import { useMsal } from "@azure/msal-react";
import * as React from 'react';
import { log } from "../helpers/utils";
import { createUserTokenRequest } from '../config/authConfig';
import { context, ACTION_TYPE } from "../State";
import DrawerMessage from "./DrawerMessage";

export default function GrantAccessButtonToggle(props){
    const {t} = useTranslation();
    const {showGrantAccessButton, setShowGrantAccessButton, adminEmail, setAdminEmail, configTokenRequest, setDisabled} = props;
    const { instance, accounts } = useMsal();
    const { dispatch } = React.useContext(context);

    log(instance);
    log(accounts);

    function handleGrantAccessResponse(response) {
        /**
         * To see the full list of response object properties, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
         */
        log(response);
        if (response !== null) {
            setAdminEmail(response.account.username);

            //save access token as a state
            dispatch({ type: ACTION_TYPE.GRAPH_API_ACCESS_TOKEN, payload: response.accessToken });
            //show user details and hide grant access button
            dispatch({ type: ACTION_TYPE.SHOW_USER_DETAILS, payload: true });
            setShowGrantAccessButton(false);
            
            if (setDisabled !== undefined) {
                setDisabled(false);
            }
            
        } else {
           log("Response is null");
        }
    }

    const handleGrantAccess = () => {
        if (showGrantAccessButton) {
            //here we should grant access to the user with the entered email
            instance.loginPopup(configTokenRequest !== undefined ? configTokenRequest : createUserTokenRequest)
                .then(handleGrantAccessResponse)
                .catch(e => {
                    log("Error occurred while granting access...");
                    log(e);
                });
        }
    }
    return (
        <div>
            {showGrantAccessButton && <Button onClick={handleGrantAccess}>{t('create_azure_user.grant_access')}</Button>}
            {!showGrantAccessButton && <DrawerMessage text = {t('create_azure_user.access_granted_for', { email: adminEmail })}/>}
        </div>
        
    )

}
