import * as React from "react";
import {Box, IconButton, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import deleteIcon from "../img/delete.svg"
import {useEffect} from "react";


function ValueField({ type, onRemove, contractReference, setContractReference }) {
    const [referenceMap, setReferenceMap] = React.useState(contractReference);
    const [customFieldName, setCustomFieldName] = React.useState(null);

    useEffect(() => {
        setContractReference(referenceMap);
    });

    const getFieldConfig = () => {
        switch (type) {
            case "Debitor":
                return {
                    label: "Debitor",
                    placeholder: "000000"
                };
            case "Kontraktnummer":
                return {
                    label: "Kontraktnummer",
                    placeholder: "000000"
                };
            case "Rabatt_id":
                return {
                    label: "Rabatt_id",
                    placeholder: "000000"
                };
            case "Custom value":
                return {
                    label: "Custom Value name",
                    placeholder: "Enter here",
                    additionalField: {
                        label: "Number",
                        placeholder: "000000"
                    }
                };
            default:
                return null;
        }
    };

    const fillContractReference = (e, labelName) => {
        let reference = referenceMap;
        if (type !== "Custom value") {
            reference[labelName] = e.target.value;
        } else {
            setCustomFieldName(e.target.value)
        }
        setReferenceMap(reference);
    }

    const setCustomFieldValue = (e) => {
        console.log(referenceMap)
        let reference = referenceMap;
        referenceMap[customFieldName] = e.target.value;
        setReferenceMap(reference);
    }
    const config = getFieldConfig();

    return (
        <FieldContainer>
            <FieldWrapper>
                <StyledTextField
                    label={config.label}
                    placeholder={config.placeholder}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => fillContractReference(e, config.label)}
                />
            </FieldWrapper>

            {config.additionalField && (
                <FieldWrapper>
                    <StyledTextField
                        label={config.additionalField.label}
                        placeholder={config.additionalField.placeholder}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setCustomFieldValue(e)}
                    />
                </FieldWrapper>
            )}

            <IconButton onClick={onRemove} aria-label="Remove field">
                <ActionIcon
                    component="img"
                    src={deleteIcon}
                    alt="Remove"
                />
            </IconButton>
        </FieldContainer>
    );
}

const FieldContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: theme.spacing(1),
    width: "100%",
    alignItems: "flex-end",
    gap: theme.spacing(1),
    flexWrap: "wrap"
}));

const FieldWrapper = styled(Box)({
    flex: 1,
    minWidth: "240px"
});

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        fontFamily: "Source Sans Pro, sans-serif",
        fontSize: "18px"
    },
    "& .MuiInputLabel-root": {
        fontFamily: "Source Sans Pro, sans-serif"
    }
}));

const ActionIcon = styled(Box)({
    width: "24px",
    height: "24px",
    objectFit: "contain"
});

export default ValueField;