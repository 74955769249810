
import oktaIcon from "../img/okta.svg";
import googleIcon from "../img/google.svg";
import salesforceIcon from "../img/salesforce.svg";
import zendeskIcon from "../img/zendesk.svg";
import officeIcon from "../img/office.svg";
import awsIcon from '../img/aws.svg';
import oidcIcon from "../img/openId.png";
import teamviewrIcon from "../img/teamviewer-icon.svg"
import keeperIcon from  "../img/keeper.png"
import { configManager } from '../App';

export let idPCertificateName = "IDEE-SAML-idp-signing.crt";
export let idPMetadataName = "IDEE-SAML-idp-metadata.xml";

export let MICROSOFT = "Microsoft";
export let OKTA = "Okta";
export let SALESFORCE = "Salesforce";
export let AWS = "AWS";
export let GSUITE = "Google Workspace";
export let ZENDESK = "Zendesk";
export let TEAMVIEWER = "Teamviewer";
export let KEEPER = "Keeper";
export let CUSTOM = "Custom";

export let serviceProvidersIcon = {
    "OKTA": oktaIcon,
    "GSUITE": googleIcon,
    "SALESFORCE": salesforceIcon,
    "MICROSOFT": officeIcon,
    "AWS": awsIcon,
    "ZENDESK": zendeskIcon,
    "TEAMVIEWER": teamviewrIcon,
    "OIDC": oidcIcon,
    "KEEPER": keeperIcon
};

export let availableServiceProviders = [
    {
        icon: oktaIcon,
        title: "Okta",
        formValue: "Okta",
        url: "https://help.okta.com/en/prod/Content/Topics/Security/idp-add-saml.htm",
        idPParameters: [
            { label: "IdP Single Sign-On URL", type: "endpointsso" },
            { label: "IdP Issuer URI", type: "entityid" },
            { label: "IdP Signature Certificate", type: "certificate" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: null,
        integrationType: "OKTA",
        sp_metadata: "file",
        elementId: "Okta-SAML"
    },
    {
        icon: googleIcon,
        title: "Google",
        formValue: "Google Workspace",
        url: "https://support.google.com/a/answer/6349809?hl=en",
        idPParameters: [
            { label: "Sign-in page URL", type: "endpointsso" },
            { label: "Sign-out page URL", type: "endpointslo" },
            { label: "Certificate", type: "certificate" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: null,
        integrationType: "GSUITE",
        sp_metadata: "domain",
        elementId: "Google-SAML"
    },
    {
        icon: salesforceIcon,
        title: "Salesforce",
        formValue: "Salesforce",
        url: "https://help.salesforce.com/s/articleView?id=sf.sso_saml.htm&type=5",
        idPParameters: [
            { label: "Metadata URL", type: "metadataurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: null,
        integrationType: "SALESFORCE",
        sp_metadata: "file",
        elementId: "Salesforce-SAML"
    },
    {
        icon: officeIcon,
        title: "Microsoft",
        formValue: "Microsoft",
        url: "https://www.getidee.com/integration-guides/how-to-configure-microsoft-365-with-ws-fed",
        idPParameters: [
            { label: "IdP Metadata URL", type: "metadataurl_wsfed" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }

        ],
        custom: null,
        integrationType: "MICROSOFT",
        sp_metadata: "domain",
        elementId: "Microsoft-MSSAML"
    },
    {
        icon: awsIcon,
        title: "AWS Single Sign On",
        formValue: "AWS",
        url: "https://docs.aws.amazon.com/singlesignon/latest/userguide/manage-your-identity-source-idp.html",
        idPParameters: [
            { label: "SSO Endpoint URL", type: "endpointssopost" },
            { label: "IdP Entity ID", type: "entityid" },
            { label: "IdP Metadata URL", type: "metadataurl" },
            { label: "IdP Certificate", type: "certificate" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: null,
        integrationType: "AWS",
        sp_metadata: "file",
        elementId: "AWS-SAML"
    },
    {
        icon: zendeskIcon,
        title: "Zendesk",
        formValue: "Zendesk",
        url: "https://support.zendesk.com/hc/en-us/articles/203663676-Enabling-SAML-single-sign-on",
        idPParameters: [
            { label: "SAML SSO URL", type: "endpointsso" },
            { label: "Remote logout URL", type: "endpointslo" },
            { label: "IdP Entity ID", type: "entityid" },
            { label: "Certificate fingerprint", type: "fingerprint" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: null,
        integrationType: "ZENDESK",
        sp_metadata: "subdomain",
        elementId: "Zendesk-SAML"
    },
    {
        icon: teamviewrIcon,
        title: "TeamViewer",
        formValue: "Teamviewer",
        url: "https://community.teamviewer.com/English/kb/articles/109722-sso",
        idPParameters: [
            { label: "Single Sign-On URL", type: "tvendpointsso" },
            { label: "IdP Entity ID", type: "entityid" },
            { label: "Single logout URL", type: "tvendpointslo" },
            { label: "Certificate", type: "certificate" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: null,
        integrationType: "TEAMVIEWER",
        sp_metadata: "custom",
        elementId: "TeamViewer-SAML"
    }, {
        icon: keeperIcon,
        title: "Keeper",
        formValue: "Keeper", 
        url: "https://docs.keeper.io/en/v/sso-connect-cloud/identity-provider-setup/generic-saml-2.0",
        idPParameters: [
            { label: "IdP Metadata", type: "metadataurl_modified" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: null,
        integrationType: "KEEPER",
        sp_metadata: "file",
        elementId: "Keeper-SAML"
    },
    {
        icon: null,
        title: "SAML APP",
        formValue: "Custom",
        url: null,
        idPParameters: [
            { label: "SAML SSO URL", type: "endpointsso" },
            { label: "Remote logout URL", type: "endpointslo" },
            { label: "IdP Entity ID", type: "entityid" },
            { label: "IdP Metadata URL", type: "metadataurl" },
            { label: "Certificate", type: "certificate" },
            { label: "System for Cross-domain Identity Management (SCIM) Endpoint URL", type: "scimendpointurl" },
            { label: "System for Cross-domain Identity Management (SCIM) Secret Token", type: "scimsecrettoken" }
        ],
        custom: "SAML",
        integrationType: "CUSTOM",
        sp_metadata: "custom",
        elementId: "Custom-SAML"
    },
    {
        icon: oidcIcon,
        title: "OIDC APP",
        formValue: "Oidc",
        url: null,
        idPParameters: [
            { label: "Client ID", type: "clientid" },
            { label: "Client Secret", type: "clientsecret" },
            { label: "Authorization endpoint", type: "endpointauthorization" },
            { label: "Token endpoint", type: "endpointtoken" },
            { label: "JWKS endpoint", type: "endpointjwks" },
            { label: "Open ID configuration", type: "configurationopenid" }
        ],
        custom: "OIDC",
        integrationType: "OIDC",
        sp_metadata: "url",
        elementId: "Custom-OIDC"
    }
]
export const getTextFieldValue = (type) => {
    switch (type) {
        case "endpointsso":
            return configManager.shibSsoRedirectEndpoint;
        case "endpointssopost":
            return configManager.shibSsoPostEndpoint;
        case "endpointslo":
            return configManager.shibSloRedirectEndpoint;
        case "entityid":
            return configManager.entityID;
        case "metadataurl":
            return configManager.shibIdpUrlMetadata;
        case "metadataurl_wsfed":
            return configManager.wsfedIdpUrlMetadata;
        case "fingerprint":
            return configManager.certificateFingerprint;
        case "endpointauthorization":
            return configManager.endpointauthorization;
        case "endpointtoken":
            return configManager.endpointtoken;
        case "endpointjwks":
            return configManager.endpointjwks;
        case "configurationopenid":
            return configManager.configurationopenid;
    }
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function isValidUUID(uuid) {
    const re = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return re.test(uuid);
}

export function isValidBase64(code) {
    const re = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return re.test(code);
}

export function isNumeric(str) { // Check if the string is not empty and if it matches the regex for numeric digits
     return /^\d+$/.test(str);
}

export function lowerAndTrimEmail(email) {
    if (email == null) { return '' }
    return email.replace(/\s+/g, '').toLowerCase();
}

export function log(msg) {
    if (process.env.REACT_APP_ENV === 'DEBUG' || process.env.NODE_ENV === 'development') {
        console.log(msg);
    }
}

export function _arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function _base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export function formatDate(longTime) {
    const date = new Date(longTime);
    return new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(date)
}

export function formatDayMonthYear(longTime) {
    const date = new Date(longTime);
    return new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: '2-digit'}).format(date)
}

export function tryLocalStorageSetItem(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        // Local storage might not be available
    }
}

export function tryLocalStorageGetItem(key, defaultValue) {
    try {
        let val = localStorage.getItem(key);
        if (val != null) {
            return val;
        }
    } catch (e) {
        // Local storage might not be available
    }
    return defaultValue;
}

export function isValidURL(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "https:";
};

export function isValidDomain(domain) {
    return /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(domain);
}

export function strip(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

function fillSiemFields (siemEnabled, selectedSiem, values) {
    var siemFields = {};
    if(siemEnabled && selectedSiem === "SENTINEL") {
        siemFields["siemWorkspaceId"] = values["workspaceId"];
        siemFields["siemPrimaryKey"] = values["primaryKey"];
        siemFields["siemServerPort"] = 0;
        siemFields["siemServerHostname"] = "";

    } else if (siemEnabled && selectedSiem === "SYSLOG_QRADAR") {
        siemFields["siemWorkspaceId"] = "";
        siemFields["siemPrimaryKey"] = "";
        siemFields["siemServerPort"] = values["serverPort"];
        siemFields["siemServerHostname"] = values["serverHostname"];
    } else {
        siemFields["siemWorkspaceId"] = "";
        siemFields["siemPrimaryKey"] = "";
        siemFields["siemServerPort"] = 0;
        siemFields["siemServerHostname"] = "";
    }
    
    return siemFields;
}

export function uploadFileUtils(api, fileUploaded, integrationName,
    formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, onSuccess, onFailure, editMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, values, contractId, identitiesLimit, integrationsLimit, contractReference) {
    var siemFields = fillSiemFields(siemEnabled, selectedSiem, values);
    api.upload(fileUploaded, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, editMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, siemFields["siemWorkspaceId"], siemFields["siemPrimaryKey"], siemFields["siemServerPort"], siemFields["siemServerHostname"], contractId, identitiesLimit, integrationsLimit, contractReference).then(function (res) {
        onSuccess(res);
    }).catch(function (error) {
        onFailure(error);

    });
}

export function addNewClientWithDomainUtils(api, domain, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, onSuccess, onFailure, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, values, contractId, identitiesLimit, integrationsLimit, contractReference) {
    var siemFields = fillSiemFields(siemEnabled, selectedSiem, values);
    api.addNewClientWithDomain(domain, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, siemFields["siemWorkspaceId"], siemFields["siemPrimaryKey"], siemFields["siemServerPort"], siemFields["siemServerHostname"], contractId, identitiesLimit, integrationsLimit, contractReference).then(function (res) {
        onSuccess(res);
    }).catch(function (error) {
        onFailure(error);
    });
}

export function addNewTeamviewerClientUtils(api,customerIdentifier, clientId, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, onSuccess, onFailure, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, values, contractId, identitiesLimit, integrationsLimit, contractReference) {
    var siemFields = fillSiemFields(siemEnabled, selectedSiem, values);
    api.addNewTeamviewerClient(customerIdentifier, clientId, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, siemFields["siemWorkspaceId"], siemFields["siemPrimaryKey"], siemFields["siemServerPort"], siemFields["siemServerHostname"], contractId, identitiesLimit, integrationsLimit, contractReference).then(function (res) {
        onSuccess(res);
    }).catch(function (error) {
        onFailure(error);
    });
}

export function generateAndAddUtils(api, domain, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, onSuccess, onFailure, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, values, contractId, identitiesLimit, integrationsLimit, contractReference) {
    var siemFields = fillSiemFields(siemEnabled, selectedSiem, values);
    api.generateAndAdd(domain, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, siemFields["siemWorkspaceId"], siemFields["siemPrimaryKey"], siemFields["siemServerPort"], siemFields["siemServerHostname"], contractId, identitiesLimit, integrationsLimit, contractReference).then(function (res) {
        onSuccess(res);
    }).catch(function (error) {
        onFailure(error);
    });
}


export function saveUtils(api, url, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, onSuccess, onFailure, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, values, contractId, identitiesLimit, integrationsLimit, contractReference) {
    var siemFields = fillSiemFields(siemEnabled, selectedSiem, values);
    api.save(url, integrationName, formValue, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, siemFields["siemWorkspaceId"], siemFields["siemPrimaryKey"], siemFields["siemServerPort"], siemFields["siemServerHostname"], contractId, identitiesLimit, integrationsLimit, contractReference).then(function (res) {
        onSuccess(res);
    }).catch(function (error) {
        onFailure(error);
    })
}

export function saveDataOidcUtils(api, clientId, url, signingAlg, encryptionAlg, encryptionMeth, authenticationMeth, clientSecret, integrationName, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, onSuccess, onFailure, editMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, selectedSiem, values, contractId, identitiesLimit, integrationsLimit, contractReference) {
    var siemFields = fillSiemFields(siemEnabled, selectedSiem, values);
    api.saveDataOidc(clientId, url, signingAlg, encryptionAlg, encryptionMeth, authenticationMeth, clientSecret, integrationName, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, editMode, integrationId, siemEnabled, selectedSiem, siemFields["siemWorkspaceId"], siemFields["siemPrimaryKey"], siemFields["siemServerPort"], siemFields["siemServerHostname"], contractId, identitiesLimit, integrationsLimit, contractReference).then(function (res) {
        onSuccess(res);
    }).catch(function (error) {
        onFailure(error);
    });

}

export function getCurrentBrowser() {
    var sBrowser, sUsrAg = navigator.userAgent;

    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        sBrowser = "unknown";
    }
    return sBrowser
}



