import MuiButton from "@mui/material/Button";
import MuiTextField from "@mui/material/TextField"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MuiLink from "@mui/material/Link";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {styled} from "@mui/material";
import {Tooltip, tooltipClasses} from "@mui/material";
import Switch from '@mui/material/Switch';
import {InputBase} from "@mui/material";


const Button = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 27,
    padding: "12px 16px",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: 'pointer',
    fontSize: 17,
    textAlign: "center",
    fontWeight: 600,
    height: 43,
    marginBottom: 36,
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    '&:hover': {
        backgroundColor: theme.palette.onHover.main,
        transform: 'scale(1.05)',
        opacity: 0.9
    },
    '&:disabled': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        opacity: 0.5
    }
}));


const RegistrationDialogButton = styled(MuiButton)(({theme}) => ({
    display: 'flex',
        padding: '12px 16px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 4,
        alignSelf: 'stretch',
        borderRadius: 24,
        textAlign: 'center',
        fontSize: 17,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        height: 'auto',
        margin: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': { 
            backgroundColor: theme.palette.primary.main
        }
}));

const InverseButton = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: '2px solid',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    minWidth: 126,
    borderRadius: 27,
    cursor: 'pointer',
    fontSize: 17,
    textAlign: "center",
    fontWeight: 600,
    height: 43,
    marginBottom: 36,
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    '&:hover': {
        backgroundColor: theme.palette.onHover.inverse,
        transform: 'scale(1.05)',
    },
    '&:disabled': {
        opacity: 0.5
    }
}));

const ButtonSignup  = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 18,
        minWidth: 195,
        padding: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
        textTransform: 'none'
}));


const InverseButtonSignup = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: 25,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText
        },
}));


const ActionButton = styled(MuiButton)(({theme}) => ({
    backgroundColor: "#F7F7F7",
    color: "#3F3F3F",
    borderRadius: 50,
    cursor: 'pointer',
    padding: "8px 12px",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
        backgroundColor: "#F7F7F7",
        transform: 'scale(1.05)',
    },
}));

const ReviewButton = styled(MuiButton)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText,
    color: "#FF5140",
    borderColor: "#FF5140",
    border: '2px solid',
    display: 'flex',
    padding: '11px 16px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '4px',
    position: 'fixed',
    left: '20px',
    bottom: '20px',
    minWidth: 133,
    height: 48,
    borderRadius: 24,
    cursor: 'pointer'
}));

const ExitButton = styled(CloseOutlinedIcon)(({theme}) => ({
    background: '#000000 0% 0% no-repeat padding-box',
    opacity: 1,
    color: 'white',
    borderRadius: 12,
    height: 12,
    width: 12
}));

const TextField = styled(MuiTextField)(({theme}) => ({
    input: {
        '@media (min-width:2560px)': {
            paddingTop:'8%',
            fontSize:'1vw',
        }, 
        '@media (min-width:1920px) and (max-width:2559px)': {
            paddingTop:'5%',
            fontSize:'1vw',
        }, 
        '@media (min-width:1366px) and (max-width:1919px)': {
            paddingTop:'5%',
            fontSize:'1vw',
        },
        
       
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
        }
    },
    label:{
        paddingLeft:'3%',
        '@media (min-width:1366px) and (max-width:1919px)': {
            paddingTop:'2%',
            fontSize:'0.9vw',
        },
        '@media (min-width:1920px)': {
            fontSize:'1vw',
        },
        color:'#152035',
        opacity: 0.62
    }
}));

const TextFieldSearch = styled(MuiTextField)(({theme}) => ({
    border: '1px solid #707070',
    width: '500px',
    height: '29px',
    marginLeft: '226px',
    marginRight: '0px',
    borderRadius: '22px',
    marginBottom: '15px',
    opacity: 1,
    '& fieldset': {
        borderRadius: '22px',
        width: '500px',
        height: '32px',
        color: 'transparent',
        border: 'transparent'
      },
      '& input': {
        width: '500px',
        height: '26px',
        borderRadius: '22px',
        transform: 'translateY(-15px)',
        border: 'transparent'
      }
}));

const Link = styled(MuiLink)(({theme}) => ({
    letterSpacing: 'var(--unnamed-character-spacing-0)',
    textAlign: 'left',
    textDecoration: 'underline',
    color: '#2A3DC7',
    opacity: 1,
    wordBreak: "break-all",
    cursor: 'pointer'
}));

const CustomCopyIcon = styled(ContentCopyIcon)(({theme}) => ({
    cursor: 'pointer',
}));

const SPIconName = styled('img')({
    width: '56px',
    height: '56px',
    display: 'inline-block',
    marginRight: '22px',
    transform: 'translateY(18px)'
});

const ErrorSpan = styled('span')({
    textAlign: 'left',
    font: 'normal normal normal 16px/22px Source Sans Pro',
    letterSpacing: '0px',
    color: "#C72941",
    opacity: 1,
    marginLeft: '10px',
    whiteSpace: 'break-spaces',
});

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: 'black'
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'black',
    },
  }));

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: '65px',
    '& .MuiSwitch-track': {
        backgroundColor: '#D6D6D6',
        height: '21px',
        width: '44px',
        borderRadius: '50px',
        '&:after': {
            backgroundColor: '#BFC7FF',
        }
    },
    '& .MuiSwitch-thumb': {
        height: '23px',
        width: '23px',
        marginTop: '2px',
        boxShadow: '0px 0px 5px #00000034',
        '&:after': {
            backgroundColor: '#293DC7'
        }
    }

}));
const StyledInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        display: 'flex',
        padding: 12,
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: 8,
        border: '1px solid',
        borderColor: '#D7D7D7',
        background: '#FFFFFF',
        width: '100%',
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(',')
      
    },
  }));
  
  

export {
    Button,
    InverseButton,
    RegistrationDialogButton,
    ActionButton,
    ReviewButton,
    ExitButton,
    TextField,
    Link,
    CustomCopyIcon,
    SPIconName, 
    ErrorSpan,
    CustomTooltip,
    TextFieldSearch,
    CustomSwitch,
    InverseButtonSignup,
    ButtonSignup,
    StyledInput
};
