import React, { useEffect } from "react";
import { styled, Typography, Box, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import MuiTableCell from '@mui/material/TableCell';
import { context, ACTION_TYPE } from "../State";
import { createTheme, ThemeProvider } from '@mui/material/styles';

//authentication constants
const AUTHN = "authn";
const WEBAUTHN = "webauthn";
const CMLA = "cmla";
const WEBAUTHN_ACCESS_KEY = "webauthnaccess";


export default function Configurator(props) {
  const { t } = useTranslation();
  const { isDomainIntegration, authenticationSettingsDto, shouldShowAccessKey, disabled } = props;
  const { state, dispatch } = React.useContext(context);
  var showAccessKey = shouldShowAccessKey !== undefined ? shouldShowAccessKey : true
  var authConfig = {
    WEBAUTHN_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.WEBAUTHN_ENABLED : true,
    WEBAUTHN_MD_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.WEBAUTHN_MD_ENABLED : true,
    AUTHN_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.AUTHN_ENABLED : true,
    // CMLA_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.CMLA_ENABLED : false
    CMLA_ENABLED: false, // CMLA DISABLED FOR NOW
    WEBAUTHN_ACCESS_KEY_ENABLED: authenticationSettingsDto !== undefined ? authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED : true
  };

  let initialChange = true;

  useEffect(() => {
    if (authenticationSettingsDto !== undefined && initialChange) {
      initialChange = false;
      var setMethod = AUTHN;
      var setFallbackMethod = undefined;
      if (authenticationSettingsDto !== undefined) {
        if (!authenticationSettingsDto.AUTHN_ENABLED) {
          if (authenticationSettingsDto.WEBAUTHN_ENABLED) {
            setMethod = WEBAUTHN;
            setFallbackMethod = authenticationSettingsDto.CMLA_ENABLED ? t('configurator.cmla') : t('configurator.none');
          } else if (authenticationSettingsDto.CMLA_ENABLED) {
            setMethod = CMLA;
            setFallbackMethod = undefined;
          } else if (authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED && !authenticationSettingsDto.CLIENT_CERTIFICATE_ENABLED) {
            setMethod = WEBAUTHN_ACCESS_KEY;
            setFallbackMethod = undefined;
          } else {
            setMethod = WEBAUTHN;
            setFallbackMethod = undefined;
          }
        }
      }
      if (state.method !== setMethod || state.fallbackMethod !== setFallbackMethod) {
        dispatch({ type: ACTION_TYPE.METHOD, payload: { method: setMethod, fallbackMethod: setFallbackMethod } });
      }
    }

  }, [])




  function updateStatus(loginMethod, typeOfMethod) {

    let setMethod = typeOfMethod === "login" ? loginMethod : WEBAUTHN;
    let setFallbackMethod = typeOfMethod === "fallback" ? loginMethod : (loginMethod === WEBAUTHN ? "none" : undefined);
    dispatch({ type: ACTION_TYPE.METHOD, payload: { method: setMethod, fallbackMethod: setFallbackMethod } });

    if (typeOfMethod === "fallback") {
      authConfig.CMLA_ENABLED = t('configurator.cmla') === loginMethod;
      authConfig.WEBAUTHN_ENABLED = true;
      authConfig.AUTHN_ENABLED = false;
      authConfig.WEBAUTHN_MD_ENABLED = false;
    } else if (typeOfMethod === "login") {
      if (AUTHN === loginMethod) {
        authConfig.AUTHN_ENABLED = true;
        authConfig.WEBAUTHN_ENABLED = true;
        authConfig.WEBAUTHN_MD_ENABLED = true;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = false;
      } else if (WEBAUTHN === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = true;
        authConfig.WEBAUTHN_MD_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = false;
      } else if (CMLA === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = false;
        authConfig.WEBAUTHN_MD_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = true;
      } else if (WEBAUTHN_ACCESS_KEY === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = true;
        authConfig.CMLA_ENABLED = false;
      }
    }
    dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: authConfig });
  }

  let methods = [
    {
      "loginMethod": t('configurator.webauthn'),
      // "fallbackLoginMethods": isDomainIntegration !== 'true' ? [t('configurator.cmla'), t('configurator.none')] : [t('configurator.none')], CMLA DISABLED FOR NOW
      "numberOfDevices": t('configurator.devices_webauthn').split('\n').map((str, index) =>
        <>
          {index === 0 && <>{str} <br></br></>}
          {index === 1 && <div style={{ color: 'gray', marginLeft: '-5px' }}> {str} </div>}
        </>
      ),
      "formValue": "webauthn"
    },
    {
      "loginMethod":
        t('configurator.authn')
      ,
      "numberOfDevices": t('configurator.devices_all_methods').split('\n').map((str, index) => <>
        {index == 0 && <>{str} <br></br></>}
        {index == 1 && <b>{str.substring(0, str.indexOf(":"))}</b>}
        {index == 1 && str.substring(str.indexOf(":"), str.length)}
      </>),
      "formValue": "authn"
    }//,
    // isDomainIntegration !== 'true' ?
    //   {
    //     "loginMethod": t('configurator.cmla'),
    //     "fallbackLoginMethods": [t('none')],
    //     "numberOfDevices": t('none'),
    //     "formValue": "cmla"
    //   } : undefined              CMLA DISABLED FOR NOW
  ]
  if (showAccessKey &&
    (authenticationSettingsDto === undefined || (authenticationSettingsDto !== undefined && !authenticationSettingsDto.CLIENT_CERTIFICATE_ENABLED))) {
    methods.push({
      "loginMethod": t('configurator.webauthn_access_key'),
      "numberOfDevices": t('configurator.devices_webauthn_access_key').split('\n').map((str, index) =>
        <>
          {index === 0 && <>{str} <br></br></>}
          {index === 1 && <span style={{ color: 'gray', marginLeft: '-5px' }}> {str} </span>}
        </>
      ),
      "formValue": "webauthnaccess"
    })
  }


  //filter undefined values
  methods = methods.filter(function (element) {
    return element !== undefined;
  });

  const loginMethodsTheme = createTheme({
    palette: {
      primary: {
        main: '#293dc7',
      },
      text: {
        primary: '#000000',
        secondary: '#7e7e7e',
      }
    },
    typography: {
      fontFamily: 'Source Sans Pro, -apple-system, Roboto, Helvetica, sans-serif',
    },
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 3,
          },
        },
      },
    },
  });

  const LoginMethodsContainer = styled(Box)({
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: '1px solid',
    borderColor: '#eeeeee',
    display: 'flex',
    maxWidth: '592px',
    flexDirection: 'column',
  });

  const LoginMethodsHeader = styled(Box)({
    alignItems: 'center',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: '#eeeeee',
    display: 'flex',
    width: '100%',
    gap: '16px',
    padding: '16px',
  });

  const LoginMethodsHeaderText = styled(Typography)({
    letterSpacing: '0.14px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1,
    '&:first-of-type': {
      width: '160px',
    },
  });

  const LoginMethodsOptionWrapper = styled(Box)({
    borderBottom: '1px solid',
    borderColor: '#eeeeee',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
  });

  const LoginMethodsOptionContent = styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    gap: '16px',
    flexWrap: 'wrap',
    padding: '8px 16px',
  });

  const LoginMethodsTitleSection = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    width: '160px',
  });

  const LoginMethodsOptionTitle = styled(Typography)({
    color: 'text.primary',
    letterSpacing: '0.09px',
    flex: 1,
    fontSize: '18px',
    lineHeight: '24px',
    whiteSpace: 'pre-line',
  });

  const LoginMethodsDetailsSection = styled(Box)({
    display: 'flex',
    minWidth: '240px',
    flexDirection: 'column',
    letterSpacing: '0.09px',
    flex: 1,
    fontSize: '18px',
  });

  const LoginMethodsDescription = styled(Typography)({
    color: 'text.secondary',
    lineHeight: '24px',
  });

  const LoginMethodsRadio = styled(Radio)({
    padding: 3,
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        '&:first-of-type': {
          transform: 'scale(1)',
        },
        '&:last-of-type': {
          transform: 'scale(0.667)',
        },
      },
    },
    '& .MuiTouchRipple-root': {
      borderRadius: 4,
    },
    color: '#293dc7',
    '&.Mui-checked': {
      color: '#293dc7',
    },
    '&.Mui-disabled': {
      color: '#808080',
    },

  });

  function LoginMethodsOption({ method, isSelected, onSelect }) {
    return (
      <LoginMethodsOptionWrapper>
        <LoginMethodsOptionContent>
          <LoginMethodsTitleSection>
            <LoginMethodsRadio
              checked={isSelected}
              onChange={onSelect}
              inputProps={{ 'aria-label': method.loginMethod }}
              disabled={disabled}
            />
            <LoginMethodsOptionTitle>{method.loginMethod}</LoginMethodsOptionTitle>
          </LoginMethodsTitleSection>
          <LoginMethodsDetailsSection>
            <LoginMethodsDescription>{method.numberOfDevices}</LoginMethodsDescription>
          </LoginMethodsDetailsSection>
        </LoginMethodsOptionContent>
      </LoginMethodsOptionWrapper>
    );
  }

  return (
    <>
      <div style={{
        width: '592px',
        height: 'auto',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.09px',
        marginBottom: '24px',
        marginTop: '24px'
      }}>{t('add_integration.configurator_heading')} </div>

      <div style={{
        width: '592px',
        height: 'auto',
        textAlign: 'left',
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.09px',
        color: "#000000",
        opacity: 1,
        marginBottom: '24px',

      }}>{t('add_integration.configurator_subheading')}</div>
      <ThemeProvider theme={loginMethodsTheme}>
        <LoginMethodsContainer>
          <LoginMethodsHeader>
            <LoginMethodsHeaderText>{t('configurator.login_method')}</LoginMethodsHeaderText>
            <LoginMethodsHeaderText>{t('configurator.number_of_devices')}</LoginMethodsHeaderText>
          </LoginMethodsHeader>
          {methods.map((method) => (
            <LoginMethodsOption
              method={method}
              isSelected={state.method === method.formValue}
              onSelect={() => updateStatus(method.formValue, "login")}
            />
          ))}
        </LoginMethodsContainer>
      </ThemeProvider>
    </>

  );
}