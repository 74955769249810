import React from "react";
import { isValidBase64, isValidDomain } from "../helpers/utils";
import { isValidUUID } from "../helpers/utils";
import { context } from "../State";
import DropDown from "./DropDown";
import TextInput from "./TextInput";
import TextFieldCopy from "./TextFieldCopy";

export default function SIEMSection(props) {

    const { t, inSidebar, selectedSiem, setSelectedSiem, values, errors, siemEnabled, isEditModeOn} = props;

    const { state } = React.useContext(context)
    const [workspaceIdSection, setWorkspaceIdSection] = React.useState(inSidebar ? state.integrationData.workspaceId : '');
    const [primaryKeySection, setPrimaryKeySection] = React.useState('');
    const [serverHostnameSection, setServerHostnameSection] = React.useState(inSidebar ? state.integrationData.serverHostname : '');
    const [serverPortSection, setServerPortSection] = React.useState(inSidebar ? state.integrationData.serverPort : '');
    const [errorPrimaryKey, setErrorPrimaryKey] = React.useState(false);
    const [errorMessagePrimaryKey, setErrorMessagePrimaryKey] = React.useState('');
    const [errorWorkspaceId, setErrorWorkspaceId] = React.useState(false);
    const [errorMessageWorkspaceId, setErrorMessageWorkspaceId] = React.useState('');
    const [errorServerHostname, setErrorServerHostname] = React.useState(false);
    const [errorMessageServerHostname, setErrorMessageServerHostname] = React.useState('');
    const [errorServerPort, setErrorServerPort] = React.useState(false);
    const [errorMessageServerPort, setErrorMessageServerPort] = React.useState('');
    const [timer, setTimer] = React.useState(null);
    const [dropdownText, setDropdownText] = React.useState(selectedSiem==="SENTINEL"? "Microsoft Sentinel" : "Q-Radar Syslog");

    const handleWorkspaceIdChange = event => {
        setWorkspaceIdSection(event.target.value);
        values["workspaceId"] = event.target.value;
        clearTimeout(timer)

        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (!isValidUUID(values["workspaceId"]) || values["workspaceId"] === "" && selectedSiem === "SENTINEL") {
                setErrorMessageWorkspaceId(t('add_integration.errors.invalid_workspace_id'));
                setErrorWorkspaceId(true);
                errors["workspaceId"] = true;
            } else {
                setErrorMessageWorkspaceId("");
                setErrorWorkspaceId(false);
                errors["workspaceId"] = false;
            }
        }, 500)

        setTimer(newTimer); 
    }

    const handlePrimaryKeyChange = event => {
        setPrimaryKeySection(event.target.value);
        values["primaryKey"] = event.target.value;

        clearTimeout(timer)
        //timer is added to delay validation until user finishes typing
        const newTimer = setTimeout(() => {
            if (!isValidBase64(values["primaryKey"]) || values["primaryKey"] === "" && selectedSiem === "SENTINEL") {
                setErrorMessagePrimaryKey(t('add_integration.errors.invalid_primary_key'));
                setErrorPrimaryKey(true);
                errors["primaryKey"] = true;
            } else {
                setErrorMessagePrimaryKey("");
                setErrorPrimaryKey(false);
                errors["primaryKey"] = false;
            }
        }, 500)

        setTimer(newTimer);
    }

    const handleServerHostnameChange = event => {
        setServerHostnameSection(event.target.value);
        values["serverHostname"] = event.target.value;

        clearTimeout(timer)
        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (!isValidDomain(values["serverHostname"]) && selectedSiem === "SYSLOG_QRADAR") {
                setErrorMessageServerHostname(t('add_integration.errors.invalid_server_hostname'));
                setErrorServerHostname(true);
                errors["serverHostname"] = true;
            } else {
                setErrorMessageServerHostname("");
                setErrorServerHostname(false);
                errors["serverHostname"] = true;
            }
        }, 500)

        setTimer(newTimer);
    }

    const handleServerPortChange = event => {
        setServerPortSection(event.target.value);
        values["serverPort"] = event.target.value;

        clearTimeout(timer)
        //timer is added to delay validation until user finishes typing
        const newTimer = setTimeout(() => {
            if (isNaN(values["serverPort"]) || Number(values["serverPort"]) < 1 || Number(values["serverPort"]) > 65535 && selectedSiem === "SYSLOG_QRADAR") {
                setErrorMessageServerPort(t('add_integration.errors.invalid_server_port'));
                setErrorServerPort(true);
                errors["serverPort"] = true;
            } else {
                setErrorMessageServerPort("");
                setErrorServerPort(false);
                errors["serverPort"] = true;
            }
        }, 500)

        setTimer(newTimer);

    }

    const handleChangeSelection = (siemType) => {
        console.log("SIEM change: " + siemType);
        setSelectedSiem(siemType);
    };

    const siemOptions = [
        {value: "SENTINEL", label: "Microsoft Sentinel"},
        {value: "SYSLOG_QRADAR", label: "Q-Radar Syslog"}
    ];

    const actions = {
        handleChangeSelection: handleChangeSelection,
        setDropdownText: setDropdownText
    };

    const stateValues = {
        dropdownText: dropdownText
    }


    return (
        
        <div style={{ whiteSpace: 'pre-wrap', display: "flex", maxWidth: "592px", flexDirection: "column", justifyContent: "flex-start" }}>

                <DropDown items={siemOptions} type={"SIEM"} label={t('add_integration.siem_dropdown_heading')} actions={actions} values={stateValues} isEdit={isEditModeOn} />

                {selectedSiem === "SENTINEL" && isEditModeOn &&
                    <>
                    <TextInput infoLabel={t('add_integration.siem_workspace_id')} value={workspaceIdSection} placeholder={"Enter here"} onChange={(event) => handleWorkspaceIdChange(event)}
                               setError={errorWorkspaceId} errorText={errorMessageWorkspaceId}/>
                    <TextInput infoLabel={t('add_integration.siem_primary_key')} value={(isEditModeOn && !siemEnabled)? primaryKeySection : null} placeholder={"Enter here"} onChange={(event) => handlePrimaryKeyChange(event)}
                               setError={errorPrimaryKey} errorText={errorMessagePrimaryKey}/>
                    </>}
                {selectedSiem === "SENTINEL" && !isEditModeOn &&
                    <>
                     <TextFieldCopy infoDescription={{type: "siem_worspace_id",label: t('add_integration.siem_workspace_id')}} isEditable={false} isCopied={true}></TextFieldCopy>
                    </>}

                {selectedSiem === "SYSLOG_QRADAR" && isEditModeOn &&
                    <>
                    <TextInput infoLabel={t('add_integration.siem_server_hostname')} value={serverHostnameSection} placeholder={"Enter here"} onChange={(event) => handleServerHostnameChange(event)}
                               setError={errorServerHostname} errorText={errorMessageServerHostname}/>
                    <TextInput infoLabel={t('add_integration.siem_server_port_number')} disabled={false} value={serverPortSection} placeholder={"Enter here"} onChange={(event) => handleServerPortChange(event)}
                               setError={errorServerPort} errorText={errorMessageServerPort}/>

                    </>}
                {selectedSiem === "SYSLOG_QRADAR" && !isEditModeOn &&
                    <>
                    <TextFieldCopy infoDescription={{type: "siem_server_hostname",label: t('add_integration.siem_server_hostname')}} isEditable={false} isCopied={true}></TextFieldCopy>
                    <TextFieldCopy infoDescription={{type: "siem_server_port",label: t('add_integration.siem_server_port_number')}} isEditable={false} isCopied={true}></TextFieldCopy>
                    </>}

        </div> )
    

}