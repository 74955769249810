import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { ToggleSwitch } from "./ToggleSwitch";
import DropDown from "./DropDown";
import {CreateContractButton} from "./CreateContractButton";
import { createTheme } from "@mui/material/styles";
import ContractComponent from "./ContractComponent";
import MuiDivider from "@mui/material/Divider";
import {SubscriptionCard} from "../pages/subscription/SubscriptionCard";
import Api from "../helpers/service";
import {useEffect} from "react";

export const theme = createTheme({
    typography: {
        fontFamily: "Source Sans Pro, sans-serif",
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&:focus": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    },
});

export function ContractSection(props) {
    const {setNewContractId, identitiesLimit, setIdentitiesLimit, integrationsLimit, setIntegrationsLimit, contractReference, setContractReference, selectedContract, setSelectedContract, assignContractChecked, setAssignContractChecked, createContract, setCreateContract, showSelectedContract, setShowSelectedContract, contract, isEdit, edition, setEdition, assignedContract, setAssignedContract} = props

    const [contractId, setContractId] = React.useState("");
    const [isMspAdmin, setIsMspAdmin] = React.useState(false);
    const [dropdownText, setDropdownText] = React.useState("");

    const values = {
        selectedContract: selectedContract,
        createContract: createContract,
        contract: contract,
        showSelectedContract: showSelectedContract,
        dropdownText: dropdownText
    }

    const actions= {
        setSelectedContract: setSelectedContract,
        setNewContractId: setNewContractId,
        setShowSelectedContract: setShowSelectedContract,
        setDropdownText: setDropdownText
    }

    console.log("Selected contract: " + selectedContract);

    useEffect( () => {
        setDropdownText(selectedContract !== null ? (selectedContract.caption ? selectedContract.caption : "") : (assignedContract  && assignedContract.caption ? assignedContract.caption : ""));

        const api = new Api();
        let customerId = "";
        let numberOfContracts = 0;
        api.getCustomerId().then((data) => {
            console.log(data);
            customerId = data.customerId;
            numberOfContracts = data.numberOfContracts;
            let suffix = (numberOfContracts + 1).toString().padStart(3,"0");
            let newContractId = customerId + '.' + suffix;
            setContractId(newContractId);
            setIsMspAdmin(customerId !== null && customerId !== undefined);
        })
    }, [assignedContract, selectedContract, setNewContractId]);

    if (isEdit) {
        return (
            <>
                <Container>
                    <HeaderSection>
                        <OptionalStepText>Optional step</OptionalStepText>
                        <HeaderContent>
                            <ToggleSwitch isChecked={assignContractChecked} setIsChecked={setAssignContractChecked} setCreateContract={setCreateContract} setSelectedContract={setSelectedContract} setDropdownText={setDropdownText} selectedContract={selectedContract} assignedContract={assignedContract}/>
                            <PageTitle variant="h1">Assigned Contract</PageTitle>
                        </HeaderContent>
                    </HeaderSection>
                    {/*Divider is added to be compatible with the current design. It should be moved to higher component during the redesign.*/}
                    {!assignContractChecked && <Divider/>}
                    <Content $isChecked={assignContractChecked}>
                        <InstructionText>
                            Please select the contract you would like to assign to this integration.
                        </InstructionText>
                        <DropDown type={"Contract"} label={"Select contract"} actions={actions} values={values} isEdit={isEdit}/>
                        { assignedContract && assignedContract.contractId && !showSelectedContract ? <SubscriptionCard contract={contract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId} closeIconNeeded={true} titleNeeded={false} setShowSelectedContract={setShowSelectedContract} setAssignedContract={setAssignedContract}/> : <></> }
                        {
                            isMspAdmin && assignContractChecked &&
                            <>
                                <ActionContainer>
                                    <OrText variant="body1">or</OrText>
                                    <CreateContractButton setCreateContract={setCreateContract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId} contractId={contractId} setShowSelectedContract={setShowSelectedContract} setAssignedContract={setAssignedContract}/>
                                </ActionContainer>
                                <ContractComponent  identitiesLimit={identitiesLimit}
                                                    setIdentitiesLimit={setIdentitiesLimit}
                                                    integrationsLimit={integrationsLimit}
                                                    setIntegrationsLimit={setIntegrationsLimit}
                                                    contractReference={contractReference}
                                                    setContractReference={setContractReference}
                                                    createContract={createContract}
                                                    setCreateContract={setCreateContract}
                                                    isChecked={assignContractChecked}
                                                    contractId={contractId}
                                                    edition={edition}
                                                    setEdition={setEdition}
                                                    setNewContractId={setNewContractId}
                                />
                            </>
                        }
                    </Content>
                    {/*Divider is added to be compatible with the current design. It should be moved to higher component during the redesign.*/}
                    {assignContractChecked && <Divider/>}
                </Container>
            </>
        );
    } else {
        return(
                <div style={{marginBottom: 20}}>
                    <SubscriptionCard contract={contract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId} closeIconNeeded={false} titleNeeded={true} contractLabelNeeded={true}/>
                </div>
        )
    }


}

const Container = styled(Box)({
    display: "flex",
    maxWidth: "592px",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: 22
});

const HeaderSection = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const OptionalStepText = styled(Typography)({
    color: "var(--Blue---primary-50, #4d70ea)",
    letterSpacing: "0.14px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 1,
    fontFamily: "Source Sans Pro, sans-serif",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const HeaderContent = styled(Box)({
    display: "flex",
    marginTop: "8px",
    width: "100%",
    alignItems: "flex-start",
    gap: "4px",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const PageTitle = styled(Typography)({
    color: "var(--Black, #000)",
    letterSpacing: "0.3px",
    flex: 1,
    flexBasis: "0%",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: 1.2,
    fontFamily: "Source Sans Pro, -apple-system, Roboto, Helvetica, sans-serif",
    margin: 0,
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const InstructionText = styled(Typography)({
    color: "var(--Black, #000)",
    letterSpacing: "0.09px",
    marginTop: "24px",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: 1,
    fontFamily: "Source Sans Pro, sans-serif",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const ActionContainer = styled(Box)({
    alignSelf: "flex-start",
    display: "flex",
    marginTop: "8px",
    alignItems: "flex-start",
    gap: "4px",
    justifyContent: "flex-start",
});

const OrText = styled(Typography)({
    color: "var(--Black, #000)",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.09px",
    width: "17px",
});

const Content = styled('div')(({ $isChecked }) => ({
    visibility: $isChecked ? "visible" : "hidden",
    height: $isChecked ? "100%" : "0%"
}));

const Divider = styled(MuiDivider) (({theme}) => ({
    width: '80%',
    marginTop: 20
}));