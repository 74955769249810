import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import addIcon from "../img/add_icon.svg";

export function CreateContractButton(props) {
    const {createContract, setCreateContract, setSelectedContract, setNewContractId, contractId, setShowSelectedContract, setAssignedContract} = props;
    const handleKeyPress = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            handleClick();
        }
    };

    const handleClick = () => {
        if (!createContract) {
            setSelectedContract(null);
            setCreateContract(true);
            setNewContractId(contractId);
            setShowSelectedContract(false);
            setAssignedContract(null);
        }
    };

    return (
        <ButtonContainer
            onClick={handleClick}
            onKeyDown={handleKeyPress}
            tabIndex={0}
            role="button"
            aria-label="Create new contract"
            createContract={createContract}
        >
            <ButtonIcon
                loading="lazy"
                component="img"
                src={addIcon}
                $createContract={createContract}
            />
            <ButtonText variant="body1"
                        createContract={createContract}>Create Contract</ButtonText>
        </ButtonContainer>
    );
}

const ButtonContainer = styled(Box)(({ createContract }) =>({
    borderRadius: "24px",
    display: "flex",
    alignItems: "center",
    fontSize: "17px",
    opacity: createContract ? 0.8 : 1,
    color: createContract ? "color: var(--Blue---primary-20, #C0D5FF)" : "var(--Blue---primary-Blue---source, #293dc7)",
    fontWeight: 600,
    textAlign: "center",
    lineHeight: 1,
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
        opacity: 0.8,
    },
    "&:focus": {
        outline: "2px solid transparent",
        outlineOffset: "2px",
    },
}));

const ButtonIcon = styled(Box)(({ createContract }) =>({
    disabled: createContract,
    marginRight: "0px",
    opacity: createContract ? 0.8 : 1
}));

const ButtonText = styled(Typography)(({ createContract }) =>({
    textDecorationColor: "var(--Blue---primary-Blue---source, #293dc7)",
    textDecoration: "underline",
    alignSelf: "stretch",
    margin: "auto 0",
    opacity: createContract ? 0.8 : 1
}));