import * as React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import createIcon from "../img/edit_groups.svg"
import {TextField, Divider, Button, Typography } from "@mui/material";
import settingsIcon from "../img/settings_groups.svg"
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from "react-i18next";

function GroupHeader  (props)  {
 const { t } = useTranslation();
 const {owner, integrationsNumber, groupName, changeGroupName, changeSettings, addIntegration, showGroupIntegrations } = props;
  return (
    <HeaderWrapper>
      <GroupStats owner={owner} integrationsNumber={integrationsNumber} firstGroupName={groupName} changeGroupName={changeGroupName} t={t} />
      <GroupActions changeSettings={changeSettings} addIntegration={addIntegration} showGroupIntegrations={showGroupIntegrations} t={t} />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  padding: "0 16px 0 24px",
  "@media (max-width: 991px)": {
    paddingLeft: "20px",
  }
});

const GroupStats = (props) => {
    const {owner, integrationsNumber, firstGroupName, changeGroupName, t} = props;
    const [isEditing, setIsEditing] = React.useState(false);
    const [groupName, setGroupName] = React.useState(firstGroupName);
    const [lastSavedgroupName, setlastSavedGroupName] = React.useState(firstGroupName);

    const handleIconClick = () => {
      setIsEditing(true);
    };
  
    const handleBlur = () => {
      setIsEditing(false);
      setGroupName(lastSavedgroupName)
    };
  
    const handleChange = (event) => {
      setGroupName(event.target.value);
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
       setIsEditing(false);
       //api for changing group name
       //changeGroupName()
       setlastSavedGroupName(groupName)
      } 
    };
  
  return (
    <StatsWrapper>
      {isEditing ? (
        <StyledTextField
          autoFocus
          value={groupName}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          variant="standard"
          InputProps={{
            disableUnderline: true
          }}
        />
      ) : (
        <GroupName>{groupName}</GroupName>
      )}
      <Button style={{ minWidth: '24px', width: '24px', height: '24px' }}>
        <img onClick={handleIconClick}
          tabIndex={0}
          role="button"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleIconClick();
            }
          }}
          src={createIcon}>
        </img>
      </Button>
      <Typography variant="h5" style={{ fontFamily: "Source Sans Pro", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.03px" }}>
        |
      </Typography>        <StatColumn>
        <StatLabel>{t("dialog.search_user_integrations")}</StatLabel>
        <StatValue>{integrationsNumber}</StatValue>
      </StatColumn>
      <Typography variant="h5" style={{ fontFamily: "Source Sans Pro", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.03px" }}>
        |
      </Typography>        <StatColumn>
        <StatLabel>{t("dialog.owner")}</StatLabel>
        <StatValue>{owner}</StatValue>
      </StatColumn>
    </StatsWrapper>
  );
  };
  
  const StatsWrapper = styled(Box)({
    alignSelf: "stretch",
    display: "flex",
    minWidth: "240px",
    alignItems: "center",
    gap: "8px",
    letterSpacing: "0.3px",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flex: 1,
    flexBasis: "0%",
    margin: "auto 0",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "20px",
    lineHeight: 1.2,
    "@media (max-width: 991px)": {
      maxWidth: "100%"
    }
  });
  
  const GroupName = styled(Box)({
    color: "#000",
    textAlign: "center",
    fontWeight: 700,
    alignSelf: "stretch",
    margin: "auto 0"
  });
  
  const StyledTextField = styled(TextField)({
    "& input": {
      color: "#000",
      textAlign: "center",
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "Source Sans Pro, sans-serif",
      padding: 0
    },
    width: "auto",
    margin: "auto 0"
  });
  
  const StatColumn = styled(Box)({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    whiteSpace: "nowrap",
    letterSpacing: "0.14px",
    lineHeight: 1,
    justifyContent: "flex-start",
    margin: "auto 0",
    "@media (max-width: 991px)": {
      whiteSpace: "initial"
    }
  });
  
  const StatLabel = styled(Box)({
    color: "#7e7e7e",
    fontWeight: 400
  });
  
  const StatValue = styled(Box)({
    color: "#000",
    textAlign: "center",
    fontWeight: 600,
    alignSelf: "flex-start"
  });

  const AddIntegrationButton = styled(Button)({
    alignItems: "flex-start",
    borderRadius: "24px",
    background: "#293dc7",
    alignSelf: "stretch",
    display: "flex",
    gap: "4px",
    color: "#fff",
    textAlign: "center",
    justifyContent: "flex-start",
    margin: "auto 0",
    padding: "12px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "17px",
    fontWeight: 600,
    lineHeight: 1,
    textTransform: "none",
    "&:hover": {
      background: "#1f2e99"
    }
  });

  const GroupActions = (props) => {
    const {changeSettings, addIntegration, showGroupIntegrations, t } = props;
    return (
      <ActionsWrapper>
        <ActionButton onClick={changeSettings}>
            <img src={settingsIcon}></img>
        </ActionButton>
        <AddIntegrationButton onClick={addIntegration}>
            <AddIcon></AddIcon>
          <span style={{paddingTop:"2px"}}>{t("add_integration.add_integration_button_heading")}</span>
        </AddIntegrationButton>
        <ActionButton onClick={showGroupIntegrations}>
            <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
        </ActionButton>
      </ActionsWrapper>
    );
  };
  
  const ActionIcon = styled("img")({
    aspectRatio: "1",
    objectFit: "contain",
    objectPosition: "center",
    width: "24px"
  });

  const ActionsWrapper = styled(Box)({
    alignSelf: "stretch",
    display: "flex",
    minWidth: "240px",
    alignItems: "center",
    gap: "8px",
    justifyContent: "flex-end",
    margin: "auto 0"
  });
  
  const ActionButton = styled(Button)({
    borderRadius: "24px",
    alignSelf: "stretch",
    display: "flex",
    alignItems: "flex-start",
    gap: "4px",
    justifyContent: "center",
    width: "48px",
    margin: "auto 0",
    padding: "12px",
    minWidth: "unset"
  });
export default GroupHeader;