import * as React from "react";
import { Typography, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import ContractHeader from "./ContractHeader";
import EditionSelector from "./ContractEditionSelector";
import ContractStats from "./ContractMetrics";
import ValueField from "./ContractValueField";
import AddValueButton from "./ContractAddValueButton";
import {formatDayMonthYear} from "../helpers/utils";
import {useEffect} from "react";

function ContractDetails(props) {
    const {identitiesLimit, setIdentitiesLimit, integrationsLimit, setIntegrationsLimit, contractReference, setContractReference, createContract, setCreateContract, isChecked, contractId, edition, setEdition, setNewContractId} = props

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [valueFields, setValueFields] = React.useState([]);

    useEffect(() => {
        //reset to default values
        if (!isChecked) {
            setValueFields([]);
            setEdition("Business");
            setIdentitiesLimit(1000000);
            setIntegrationsLimit(5);
        }
    }, [isChecked, setEdition, setIdentitiesLimit, setIntegrationsLimit]);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleValueSelect = (value) => {
        if (valueFields.length < 3) {
            setValueFields([...valueFields, value]);
        }
        handleMenuClose();
    };

    const handleRemoveField = (fieldType, index) => {
        //removing value filed from field array
        const newFields = valueFields.filter((_, i) => i !== index);
        setValueFields(newFields);

        //removing reference value after removing value field
        let newContractReference = contractReference;
        delete newContractReference[fieldType]
        setContractReference(newContractReference);
    };

    const contractStats = [
        {label: "Available integrations", value: integrationsLimit === 1000 ? "∞" : integrationsLimit},
        {label: "Identities purchased", value: identitiesLimit === 1000000 ? "∞" : identitiesLimit},
        {label: "Start date", value: formatDayMonthYear(Date.now())},
        {label: "End date", value: "∞"}
    ];

    const menuOptions = [
        "Debitor",
        "Kontraktnummer",
        "Rabatt_id",
        "Custom value"
    ];

    return (
        <ContractContainer $createContract={createContract} $isChecked={isChecked}>
            <ContractHeader contractNumber={contractId} setCreateContract={setCreateContract} setNewContractId={setNewContractId}/>

            <EditionSelector setAvailableIntegrations={setIntegrationsLimit}
                             setNumberOfIdentities={setIdentitiesLimit}
                             edition={edition}
                             setEdition={setEdition}
            />

            <ContractStats stats={contractStats}/>

            <ValueHeading variant="h6">Value</ValueHeading>

            {valueFields.map((fieldType, index) => (
                <ValueField
                    key={`${fieldType}-${index}`}
                    type={fieldType}
                    contractReference={contractReference}
                    setContractReference={setContractReference}
                    onRemove={() => handleRemoveField(fieldType, index)}
                />
            ))}

            {valueFields.length < 3 && (
                <AddValueButton
                    anchorEl={anchorEl}
                    onMenuClick={handleMenuClick}
                    onMenuClose={handleMenuClose}
                    onValueSelect={handleValueSelect}
                    menuOptions={menuOptions.filter(option => !valueFields.includes(option) || option === "Custom value")}
                />
            )}
        </ContractContainer>
    );
}

const ContractContainer = styled(Container)(({ theme, $createContract, $isChecked }) => ({
    visibility: ($createContract && $isChecked) ? "visible" : "hidden",
    height: ($createContract && $isChecked) ? "100%" : "0%",
    alignSelf: "center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    maxWidth: "592px",
    flexDirection: "column",
    padding: theme.spacing(1)
}));

const ValueHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    letterSpacing: "0.09px",
    marginTop: theme.spacing(1),
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: 1,
    fontFamily: "Source Sans Pro, sans-serif"
}));

export default ContractDetails;