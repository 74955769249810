import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from "@mui/material";
import {styled} from "@mui/styles";

export default function DrawerMessage(props) {

    const {text} = props;
    const MessageContainer = styled(Box)({
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        borderRadius: "12px",
        backgroundColor: "#E5FFEA",
        gap: "4px",
        color: "#00a825",
        letterSpacing: "0.09px",
        flexWrap: "wrap",
        padding: "12px 8px",
        fontFamily: "Source Sans Pro, sans-serif",
        fontSize: "18px",
        lineHeight: 1,
        fontWeight: 400,
    });

    const MessageText = styled(Typography)({
        flex: 1,
        flexBasis: "0%",
        "@media (max-width: 991px)": {
            maxWidth: "100%",
        },
    });

    return (
    <MessageContainer>
        <CheckIcon fontSize="inherit" style={{height:'22px', width: '22px', color: "#00a825"}}/>
        <MessageText>{text}</MessageText>
    </MessageContainer>
    );



}